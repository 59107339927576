<template>
	<div>
		<el-dialog
			:title="`${pageType === 'add' ? '新增班组' : pageType === 'edit' ? '编辑班组' : '班组详情'}`"
			:visible.sync="dialogVisible"
			width="820px"
			:lock-scroll="false"
		>
			<!-- 新增 | 编辑 -->
			<template v-if="pageType === 'add' || pageType === 'edit'">
				<div>
					<el-form label-position="right" label-width="120px" ref="form" :model="formLabelAlign" :rules="rules">
						<el-form-item label="班组" prop="groupName">
							<el-input v-model="formLabelAlign.groupName"></el-input>
						</el-form-item>

						<el-form-item class="form-item-display form-item-input-display" label="关联班制" prop="shiftDetailId">
							<el-select v-model="formLabelAlign.shiftDetailId" filterable placeholder="请选择">
								<el-option
									v-for="item in shiftList"
									:key="`shift_` + item.shiftId"
									:label="item.timeShiftTypeName"
									:value="item.shiftId"
								>
								</el-option>
							</el-select>
							<span class="form-item-hr"> : </span>

							<el-input v-model="formLabelAlign.workStartTime" disabled></el-input>
							<span class="form-item-hr">-</span>
							<el-input v-model="formLabelAlign.workEndTime" disabled></el-input>
						</el-form-item>
					</el-form>

					<div>
						<p style="font-size: 14px; margin-bottom: 12px">人车绑定</p>
						<el-table :data="formLabelAlign.detailReqList" border style="width: 100%">
							<el-table-column type="index" align="center" label="序号" width="60"> </el-table-column>
							<el-table-column prop="spec" min-width="100" label="车辆">
								<template slot-scope="scope">
									<el-select v-model="scope.row.vehicleId" filterable placeholder="请选择">
										<el-option
											v-for="item in carDataList"
											:key="item.vehicleInfo.vehicleId"
											:label="item.vehicleInfo.plateNum"
											:value="item.vehicleInfo.vehicleId"
											:disabled="item.disabled"
										>
										</el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="spec" min-width="100" label="轮班司机">
								<template slot-scope="scope">
									<el-select v-model="scope.row.driverId" filterable placeholder="请选择">
										<el-option
											v-for="item in driverDataList"
											:key="item.driverInfo.driverId"
											:label="item.driverInfo.cName"
											:value="item.driverInfo.driverId"
											:disabled="item.disabled"
										>
										</el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column prop="spec" min-width="100" label="轮休司机">
								<template slot-scope="scope">
									<el-select v-model="scope.row.rotatingDriverId" filterable placeholder="请选择">
										<el-option
											v-for="item in driverDataList"
											:key="item.driverInfo.driverId"
											:label="item.driverInfo.cName"
											:value="item.driverInfo.driverId"
											:disabled="item.disabled"
										>
										</el-option>
									</el-select>
								</template>
							</el-table-column>

							<el-table-column align="center" label="操作">
								<template slot-scope="scope">
									<el-button
										:disabled="formLabelAlign.detailReqList.length <= 1"
										icon="el-icon-minus"
										style="padding: 4px"
										circle
										@click="delCommodityInfo(scope.$index)"
									></el-button>
									<el-button
										v-if="scope.$index === formLabelAlign.detailReqList.length - 1"
										icon="el-icon-plus"
										style="padding: 4px"
										circle
										@click="addCommodityInfo(scope.$index)"
									></el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="handleClick" :loading="btnLoading">确 定</el-button>
				</span>
			</template>

			<!-- 详情 -->
			<template v-if="pageType === 'detail'">
				<div class="detail-item">
					<span>班组名称：</span>
					{{ formLabelAlign.groupName }}
				</div>
				<div class="detail-item">
					<span>关联班制：</span>
					{{ shiftList.find(item => item.shiftId === formLabelAlign.shiftDetailId).timeShiftTypeName }}
				</div>
				<div class="detail-item">
					<span>班制首末时间：</span>
					{{ formLabelAlign.workStartTime }} ~ {{ formLabelAlign.workEndTime }}
				</div>
				<div>
					<p style="font-size: 14px; margin-bottom: 12px">人车绑定：</p>
					<el-table :data="formLabelAlign.detailReqList" border>
						<el-table-column type="index" align="center" label="序号" width="60"> </el-table-column>
						<el-table-column prop="vehicleNo" align="center" label="车辆"> </el-table-column>
						<el-table-column prop="driverName" align="center" label="轮班司机"> </el-table-column>
						<el-table-column prop="rotatingDriverName" align="center" label="轮休息司机"> </el-table-column>
					</el-table>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		shiftChangeType: {
			type: String,
			default: "1" //1-按时间换班，2-按趟次换班
		},
		routeId: {
			type: String,
			default: ""
		},
		dataList: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			pageType: "add",
			shiftList: [],
			carDataList: [],
			driverDataList: [],
			dialogVisible: false,
			btnLoading: false,
			formLabelAlign: {
				id: null,
				shiftDetailId: "",
				groupName: "",
				workStartTime: "",
				workEndTime: "",
				detailReqList: [
					{
						vehicleId: "",
						driverId: "",
						rotatingDriverId: "" //轮休司机
					}
				]
			},
			rules: {
				groupName: [{ required: true, message: "请输入班组名称", trigger: "blur" }],
				shiftDetailId: [{ required: true, message: "请选择班制", trigger: "change" }]
			}
		};
	},
	created() {
		this.getCarList();
		this.getDriverList();
	},
	watch: {
		"formLabelAlign.shiftDetailId": {
			handler: function (val) {
				if (val) {
					let _find = this.shiftList.find(item => item.shiftId === val);
					this.formLabelAlign.workStartTime = _find.startTime;
					this.formLabelAlign.workEndTime = _find.endTime;
				} else {
					this.formLabelAlign.workStartTime = "";
					this.formLabelAlign.workEndTime = "";
				}
			}
		},
		dataList: {
			handler: function (val) {
				let _shiftList = [];
				val.map(item => {
					let timeShiftsTypeOptions = {
						1: "全天班",
						2: "早班",
						3: "晚班",
						4: "早晚班"
					};
					item.detailVoList.map(sItem => {
						_shiftList.push({
							shiftId: sItem.id,
							startTime: sItem.startTime,
							endTime: sItem.endTime,
							timeShiftType: sItem.timeShiftType,
							timeShiftTypeName: item.shiftName + " - " + timeShiftsTypeOptions[sItem.timeShiftType]
						});
					});
				});

				this.shiftList = _shiftList;
			},
			deep: true
		},
		"formLabelAlign.detailReqList": {
			handler: function (val) {
				this.carDataList = this.carDataList.map(item => {
					return {
						...item,
						disabled: false
					};
				});
				this.driverDataList = this.driverDataList.map(item => {
					return {
						...item,
						disabled: false
					};
				});
				val.map(item => {
					let _cindex = this.carDataList.findIndex(c_item => c_item.vehicleInfo.vehicleId === item.vehicleId);
					if (_cindex !== -1) {
						this.carDataList[_cindex].disabled = true;
					}

					let _dindex = this.driverDataList.findIndex(d_item => d_item.driverInfo.driverId === item.driverId);
					if (_dindex !== -1) {
						this.driverDataList[_dindex].disabled = true;
					}

					let __dindex = this.driverDataList.findIndex(d_item => d_item.driverInfo.driverId === item.rotatingDriverId);
					if (__dindex !== -1) {
						this.driverDataList[__dindex].disabled = true;
					}
				});
			},
			deep: true
		}
	},
	methods: {
		open(type, _data) {
			if (this.$refs["form"]) {
				this.$refs["form"].resetFields();
			}
			this.pageType = type;
			if (type === "add") {
				this.formLabelAlign.id = null;
				this.formLabelAlign.id = "";
				this.formLabelAlign.shiftDetailId = "";
				this.formLabelAlign.groupName = "";
				this.formLabelAlign.detailReqList = [
					{
						vehicleId: "",
						driverId: "",
						rotatingDriverId: ""
					}
				];
			} else {
				this.formLabelAlign.id = _data.id;
				this.formLabelAlign.shiftDetailId = "" + _data.shiftDetailId;
				this.formLabelAlign.groupName = _data.groupName;
				this.formLabelAlign.detailReqList = _data.detailVoList || [];
			}

			this.dialogVisible = true;
		},

		/** 获取车辆列表 */
		getCarList() {
			let url = "/base/vehicle/queryPage";
			let param = {
				pageIndex: 1,
				pageSize: 1000
			};
			this.$http.post(url, param).then(res => {
				this.carDataList = res.detail.list.map(item => {
					return {
						...item,
						disabled: false
					};
				});
			});
		},
		/** 获取司机列表 */
		getDriverList() {
			let url = "/base/driver/queryPage";
			let param = {
				pageIndex: 1,
				pageSize: 1000
			};
			this.$http.post(url, param).then(res => {
				this.driverDataList = res.detail.list.map(item => {
					return {
						...item,
						disabled: false
					};
				});
			});
		},

		/**  - 删除 */
		delCommodityInfo(index) {
			this.formLabelAlign.detailReqList.splice(index, 1);
		},

		/**  - 添加 */
		addCommodityInfo(index) {
			this.formLabelAlign.detailReqList.splice(index + 1, 0, {});
		},

		handleClick() {
			console.log(this.formLabelAlign, "formLabelAlignformLabelAlign");
			this.$refs["form"].validate(valid => {
				if (valid) {
					let url = "/schedule/group/saveOrUpdate";
					let param = {
						...this.formLabelAlign,
						routeId: this.routeId,
						shiftChangeType: this.shiftChangeType,
						detailReqList: this.formLabelAlign.detailReqList.map(item => {
							return {
								...item,
								vehicleNo: this.carDataList.find(c_item => c_item.vehicleInfo.vehicleId === item.vehicleId).vehicleInfo.plateNum,
								driverName: this.driverDataList.find(d_item => d_item.driverInfo.driverId === item.driverId).driverInfo.cName,
								rotatingDriverName: this.driverDataList.find(d_item => d_item.driverInfo.driverId === item.rotatingDriverId)
									.driverInfo.cName
							};
						})
					};
					this.btnLoading = true;
					this.$http
						.post(url, param, { isRequestParam: false })
						.then(res => {
							if (res.code === "0") {
								this.$message({
									message: `${this.pageType === "add" ? "新增" : "修改"}成功`,
									type: "success"
								});

								this.dialogVisible = false;
								this.$emit("refreshList");
							}
						})
						.finally(() => {
							this.btnLoading = false;
						});
				}
			});
		}
	}
};
</script>

<style scoped lang="scss">
::v-deep .form-item-display {
	.el-form-item__content {
		display: flex;
		> div {
			flex: 1;
		}
	}
	.form-item-hr {
		margin: 0 8px;
	}
}
::v-deep .form-item-input-display {
	.el-form-item__content {
		.el-input.is-disabled .el-input__inner {
			background-color: #fff;
			color: #606266;
		}
	}
}

.detail-item {
	display: flex;
	font-size: 14px;
	margin-bottom: 12px;
	> span {
		width: 120px;
	}
}
</style>
