<template>
	<div style="height: 100%">
		<div id="shift-system-box">
			<div class="left-box">
				<div class="left-box-title">线路信息</div>
				<div
					class="route-item"
					:class="{ 'route-item-active': routeId === item.routeId }"
					v-for="item in routeList"
					:key="item.routeId"
					@click="getScheduleShiftList(item.routeId)"
				>
					{{ item.cName }}
				</div>
			</div>
			<div class="right-box">
				<div class="box-show-item">
					<p class="box-title">班制管理</p>
					<el-button type="primary" @click="openEdit('add')">新增</el-button>
					<div
						class="right-item"
						v-loading="shiftSystemLoading"
						element-loading-text="数据加载中..."
						element-loading-spinner="el-icon-loading"
					>
						<div class="right-item-table">
							<div>班制名称</div>
							<div>
								<div class="time-axis">
									<!-- 960  40  8 -->
									<!-- 小时刻度  40：一小时刻度(class:line)宽度   8：最小刻度（class:second）宽度-->
									<div class="hourLine">
										<div class="line" v-for="index of 24" :key="index" :style="{ left: `${40 * (index - 1)}px` }">
											<div class="secondLine">
												<div class="second" v-for="index of 5" :key="index" :style="{ left: `${6 * (index - 1)}px` }"></div>
											</div>
											<span v-if="index === 1" class="time-str time-str-0"> 00:00</span>
											<span v-if="index % 3 === 0" class="time-str">
												{{ `${String(index).padStart(2, "0")}:00` }}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div>操作</div>
						</div>
						<template v-for="(item, index) in dataList">
							<div v-if="item.workType === '10'" :key="`${index}_${item.id}`" class="right-item-table">
								<div>{{ item.shiftName }}</div>
								<div>
									<div
										v-for="(subItem, index) in item._detailVoList"
										:key="index"
										class="point"
										:style="{
											left: `${subItem.positionLeft}px`,
											background: subItem.background,
											width: `${subItem.width}px`
										}"
									>
										<p>{{ subItem.timeShiftTypeName }}</p>
										<p>{{ subItem.startTime }} ~ {{ subItem.endTime }}</p>
									</div>
								</div>
								<div></div>
							</div>
							<div :key="item.id" v-else class="right-item-table">
								<div>{{ item.shiftName }}</div>
								<div>
									<div
										v-for="(subItem, index) in item._detailVoList"
										:key="index"
										class="point"
										:style="{
											left: `${subItem.positionLeft}px`,
											background: subItem.background,
											width: `${subItem.width}px`
										}"
									>
										<p>{{ subItem.timeShiftTypeName }}</p>
										<p>{{ subItem.startTime }} ~ {{ subItem.endTime }}</p>
									</div>
								</div>
								<div>
									<el-button type="text" @click="openEdit('edit', item)">编辑</el-button>
									<el-button type="text" @click="handelDelList(item)">删除</el-button>
								</div>
							</div>
						</template>
					</div>
				</div>
				<!--  -->
				<div class="box-show-item" style="margin-top: 30px">
					<groupList :routeId="routeId" :dataList="dataList"></groupList>
				</div>
			</div>
		</div>

		<shiftEdit ref="shiftEdit" @refreshList="refreshList"></shiftEdit>
	</div>
</template>
<script>
import shiftEdit from "./components/shift-edit.vue";
import groupList from "./components/group-list";
export default {
	components: { shiftEdit, groupList },
	data() {
		return {
			shiftSystemLoading: true,
			routeId: "",
			dataList: [],
			routeList: []
		};
	},
	async created() {
		this.shiftSystemLoading = true;
		await this.getRouteList();
		if (this.routeList[0].routeId) {
			this.getScheduleShiftList(this.routeList[0].routeId);
		}
	},
	methods: {
		/** 获取线路 */
		async getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			return this.$http
				.post(url)
				.then(({ detail }) => {
					this.routeList = detail;
				})
				.catch(() => {
					this.shiftSystemLoading = false;
				});
		},

		getScheduleShiftList(routeId) {
			this.shiftSystemLoading = true;
			this.routeId = routeId;
			let url = "/schedule/shift/list";
			let param = {
				routeId
			};
			this.$http
				.post(url, param, { isRequestParam: false })
				.then(res => {
					this.DataRecast(res.detail);
				})
				.finally(() => {
					this.shiftSystemLoading = false;
				});
		},

		refreshList() {
			this.getScheduleShiftList(this.routeId);
		},

		// 时分秒转化秒
		hmsToS(time) {
			let hour = time.split(":")[0];
			let min = time.split(":")[1];
			let sec = time.split(":")[2];
			return Number(Number(hour) * 3600) + Number(Number(min) * 60) + Number(sec);
		},

		/** 随机获取颜色值 */
		getColorRandom() {
			let colorArr = ["#ccf6ea", "#409EFF", "#67C23A", "#E6A23C", "#F56C6C"];
			var randomIndex = Math.floor(Math.random() * colorArr.length);
			return colorArr[randomIndex];
		},

		getColor(index, length, subIndex) {
			let colorVal = "";
			switch (index) {
				case 0:
					colorVal = "#2cdee8";
					break;
				case 1:
					if (length > 1) {
						if (subIndex === 0) {
							colorVal = "#ccf6ea";
						} else {
							colorVal = "#4ad15b";
						}
					} else {
						colorVal = "#ccf6ea";
					}
					break;
				case 2:
					if (length > 1) {
						if (subIndex === 0) {
							colorVal = "#f2ec32";
						} else {
							colorVal = "#fc6b3f";
						}
					} else {
						colorVal = "#f2ec32";
					}
					break;
				case 3:
					if (length > 1) {
						if (subIndex === 0) {
							colorVal = "#9bdfdd";
						} else {
							colorVal = "#f98731";
						}
					} else {
						colorVal = "#9bdfdd";
					}
					break;
				default:
					this.getColorRandom();
					break;
			}

			return colorVal;
		},

		/** 计算时间渲染区块 */
		DataRecast(data) {
			// 24小时 总时间秒数
			let z_times = 24 * 60 * 60;
			// 需要绘制的数据
			this.dataList = data.map((item, index) => {
				let timeShiftsTypeOptions = {
					1: "全天班",
					2: "早班",
					3: "晚班",
					4: "早晚班"
				};
				let _detailVoList = item.detailVoList.map((subItem, subIndex) => {
					return {
						startTime: subItem.startTime, // 开始时间 秒
						endTime: subItem.endTime, // 结束时间 秒
						// background: this.getColorRandom(),
						background: this.getColor(index, item.detailVoList.length, subIndex),
						positionLeft: (this.hmsToS(subItem.startTime + ":00") / z_times) * 960, // 定位 左偏移量   960 父级box(class:time-axis) 宽度
						width: ((this.hmsToS(subItem.endTime + ":00") - this.hmsToS(subItem.startTime + ":00")) / z_times) * 960, // 时间段占比宽度
						timeShiftTypeName: item.shiftName + " - " + timeShiftsTypeOptions[subItem.timeShiftType]
					};
				});

				return {
					...item,
					_detailVoList
				};
			});
		},

		openEdit(type, itemData) {
			let _data = {
				routeId: this.routeId,
				workStartTime: this.dataList.find(item => item.workType === "10").workStartTime,
				workEndTime: this.dataList.find(item => item.workType === "10").workEndTime
			};
			if (type === "edit" || type === "detail") {
				_data = { ..._data, itemData: itemData };
			}

			this.$refs["shiftEdit"].open(type, _data);
		},

		/** 删除 */
		handelDelList(data) {
			let url = "/schedule/shift/removeById";
			let param = {
				id: data.id
			};

			this.$http.post(url, param).then(res => {
				if (res.code === "0") {
					this.refreshList();
					this.$message({
						message: `删除成功`,
						type: "success"
					});
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.jy_admin .right {
	background: #f6f7fc;
}
#shift-system-box {
	width: 100%;
	height: 100%;
	display: flex;
	.left-box {
		width: 180px;
		background: rgba(25, 59, 128, 0.8);
		color: #fff;
		border-radius: 5px 5px 0 0;
		.left-box-title {
			font-size: 16px;
			padding: 10px 0;
			font-weight: 700;
			text-align: center;
			border-bottom: 5px solid #f6f7fc;
		}
		.route-item {
			padding: 15px 0;
			font-size: 16px;
			text-align: center;
			cursor: pointer;
		}
		.route-item-active {
			background: linear-gradient(90deg, #4accff 0%, rgba(69, 122, 230, 0) 100%);
			position: relative;
			&::after {
				content: "";
				position: absolute;
				height: 100%;
				width: 4px;
				top: 0;
				left: 0;
				background-color: #66ffff;
			}
		}
	}
	.right-box {
		flex: 1;
		padding-left: 30px;
		box-sizing: border-box;
		.right-item {
			width: 100%;
			margin-top: 10px;
			.right-item-table {
				width: 100%;
				display: flex;
				border-bottom: 1px solid #cfcfcf;
				height: 60px;
				> div:nth-child(1) {
					width: 180px;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					flex: 1;
				}
				> div:nth-child(2) {
					width: 1000px;
					position: relative;
					display: flex;
					flex-direction: column-reverse;
				}
				> div:nth-child(3) {
					flex: 1;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		.box-show-item {
			padding: 0px 20px 30px 20px;
			box-sizing: border-box;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
			border: 1px solid #e6ebf5;
			border-radius: 6px;
		}
	}
}

.time-axis {
	position: relative;
	height: 26px;
	width: 960px;
	color: #fff;
	.hourLine {
		height: 70%;
		width: 100%;
		position: absolute;
		bottom: 0;
		display: flex;
		.line {
			position: absolute;
			width: 40px;
			height: 100%;
			border-right: 1px solid #333;
			.secondLine {
				height: 50%;
				width: 100%;
				position: absolute;
				bottom: 0;
				display: flex;
				.second {
					position: absolute;
					width: 8px;
					height: 100%;
					border-right: 1px solid #888;
				}
			}
			.time-str {
				position: absolute;
				top: -20px;
				color: #333;
				right: -16px;
				font-size: 14px;
			}
			.time-str-0 {
				left: -16px;
			}
		}
		.line:first-child {
			border-left: 1px solid #333;
		}
	}
}
.point {
	position: absolute;
	height: 100%;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	p {
		font-size: 14px;
	}
}

.box-title {
	border-bottom: 1px solid #e6ebf5;
	color: #333;
	padding: 14px 15px 7px;
	margin-bottom: 15px;
	font-weight: 700;
	font-size: 16px;
}
</style>
