<template>
	<div>
		<el-dialog
			:title="`${pageType === 'add' ? '新增' : '编辑'}班制`"
			:visible.sync="dialogVisible"
			:lock-scroll="false"
			width="820px"
		>
			<div>
				<el-form label-position="right" label-width="120px" ref="form" :model="formLabelAlign" :rules="rules">
					<el-form-item class="form-item-display form-item-input-display" label="线路首末班">
						<el-input v-model="formLabelAlign.workStartTime" disabled></el-input>
						<span class="form-item-hr">-</span>
						<el-input v-model="formLabelAlign.workEndTime" disabled></el-input>
						<span class="form-item-hr">-</span>
						<el-select v-model="formLabelAlign.timeShiftsType" placeholder="请选择">
							<el-option v-for="item in timeShiftsTypeOptions" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="班制名称" prop="shiftName">
						<el-input v-model="formLabelAlign.shiftName"></el-input>
					</el-form-item>

					<template v-for="(item, index) in formLabelAlign.timeList">
						<el-form-item
							:key="index"
							:rules="rules['timeRules' + index]"
							:prop="`timeList.${index}.startTime`"
							class="form-item-display"
							label="上班时间"
							v-if="index === 0 ? true : formLabelAlign.timeShiftsType === '4' ? true : false"
						>
							<el-time-select
								placeholder="起始时间"
								v-model="item.startTime"
								:picker-options="{
									start: pickerStart,
									step: '00:05',
									end: pickerEnd,
									maxTime: item.endTime
								}"
							>
							</el-time-select>
							<span class="form-item-hr">-</span>
							<el-time-select
								placeholder="结束时间"
								v-model="item.endTime"
								:picker-options="{
									start: pickerStart,
									step: '00:05',
									end: pickerEnd,
									minTime: item.startTime
								}"
							>
							</el-time-select>
							<span class="form-item-hr">-</span>
							<el-select v-model="item.timeShiftsType" placeholder="请选择" disabled>
								<el-option v-for="item in timeShiftsTypeOptions" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</template>
				</el-form>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleClick" :loading="btnLoading">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			dialogVisible: false,
			btnLoading: false,
			pickerStart: "00:00",
			pickerEnd: "23:55",
			pageType: "add",
			routeId: "",
			timeShiftsTypeOptions: [
				{
					value: "1",
					label: "全天班"
				},
				{
					value: "2",
					label: "早班"
				},
				{
					value: "3",
					label: "晚班"
				},
				{
					value: "4",
					label: "早晚班"
				}
			],
			formLabelAlign: {
				id: null,
				workStartTime: "05:25:00",
				workEndTime: "22:00:00",
				timeShiftsType: "1",
				shiftName: "",
				timeList: [
					{ id: null, startTime: "", endTime: "", timeShiftsType: "1" },
					{ id: null, startTime: "", endTime: "", timeShiftsType: "1" }
				]
			},
			rules: {
				shiftName: [{ required: true, message: "请输入班制名称", trigger: "blur" }],
				timeRules0: [
					{
						required: true,
						validator: (rule, value, callback) => {
							if (!this.formLabelAlign.timeList[0].startTime) {
								callback(new Error("请选择起始时间"));
							} else if (!this.formLabelAlign.timeList[0].endTime) {
								callback(new Error("请选择结束时间"));
							} else {
								callback();
							}
						},
						trigger: "blur"
					}
				],
				timeRules1: [
					{
						required: true,
						validator: (rule, value, callback) => {
							if (!this.formLabelAlign.timeList[1].startTime) {
								callback(new Error("请选择起始时间"));
							} else if (!this.formLabelAlign.timeList[1].endTime) {
								callback(new Error("请选择结束时间"));
							} else {
								callback();
							}
						},
						trigger: "blur"
					}
				]
			}
		};
	},
	watch: {
		"formLabelAlign.timeShiftsType": {
			handler: function (val) {
				if (val === "") {
					_timeShiftsType = "";
					this.formLabelAlign.timeList = this.formLabelAlign.timeList.map(item => {
						return {
							...item,
							timeShiftsType: ""
						};
					});
				} else if (val === "4") {
					this.formLabelAlign.timeList = this.formLabelAlign.timeList.map((item, index) => {
						return {
							...item,
							timeShiftsType: index === 0 ? "2" : "3"
						};
					});
				} else {
					this.formLabelAlign.timeList = this.formLabelAlign.timeList.map(item => {
						return {
							...item,
							timeShiftsType: val
						};
					});
				}
			}
		}
	},
	methods: {
		open(type, data) {
			if (this.$refs["form"]) {
				this.$refs["form"].resetFields();
			}
			this.dialogVisible = true;
			this.pageType = type;
			this.routeId = data.routeId;
			this.formLabelAlign.workStartTime = data.workStartTime;
			this.formLabelAlign.workEndTime = data.workEndTime;
			this.pickerStart = data.workStartTime;
			this.pickerEnd = data.workEndTime;

			if (type === "add") {
				this.formLabelAlign.id = null;
				this.formLabelAlign.timeShiftsType = "1";
				this.formLabelAlign.shiftName = "";
				this.formLabelAlign.timeList = [
					{ id: null, startTime: "", endTime: "", timeShiftsType: "1" },
					{ id: null, startTime: "", endTime: "", timeShiftsType: "1" }
				];
			} else {
				this.formLabelAlign.id = data.itemData.id;
				this.formLabelAlign.timeShiftsType = data.itemData.workType;
				this.formLabelAlign.shiftName = data.itemData.shiftName;
				this.formLabelAlign.timeList = data.itemData.detailVoList.map(item => {
					return {
						id: item.id,
						startTime: item.startTime,
						endTime: item.endTime,
						timeShiftsType: item.timeShiftType
					};
				});

				if (data.itemData.workType != "4") {
					this.formLabelAlign.timeList = [
						...this.formLabelAlign.timeList,
						{ id: null, startTime: "", endTime: "", timeShiftsType: data.itemData.workType }
					];
				}
			}
		},

		handleClick() {
			this.$refs["form"].validate(valid => {
				if (valid) {
					let _timeList = [];
					if (this.formLabelAlign.timeShiftsType !== "4") {
						_timeList = [this.formLabelAlign.timeList[0]];
					} else {
						_timeList = this.formLabelAlign.timeList;
					}
					let url = "/schedule/shift/saveOrUpdate";
					let param = {
						id: this.formLabelAlign.id,
						routeId: this.routeId, //线路id ,
						shiftName: this.formLabelAlign.shiftName, //班制名称
						workEndTime: this.formLabelAlign.workEndTime, //线路首末班结束时间 ,
						workStartTime: this.formLabelAlign.workStartTime, //线路首末班开始时间 ,
						workType: this.formLabelAlign.timeShiftsType, //班制类型：1-全天班，2-早班，3-晚班，4-早晚班
						detailReqList: _timeList.map(item => {
							return {
								shiftId: this.formLabelAlign.id,
								id: item.id, //主键ID ,
								startTime: item.startTime, //上班开始时间 ,
								endTime: item.endTime, //上班结束时间 ,
								timeShiftType: item.timeShiftsType //上班类型：1-全天班，2-早班，3-晚班，4-早晚班 ,
							};
						})
					};
					this.btnLoading = true;
					this.$http
						.post(url, param, { isRequestParam: false })
						.then(res => {
							if (res.code === "0") {
								this.dialogVisible = false;
								this.$emit("refreshList");
								this.$message({
									message: `${this.pageType === "add" ? "新增" : "修改"}成功`,
									type: "success"
								});
							}
						})
						.finally(() => {
							this.btnLoading = false;
						});
				}
			});
		}
	}
};
</script>

<style scoped lang="scss">
::v-deep .form-item-display {
	.el-form-item__content {
		display: flex;
		> div {
			flex: 1;
		}
	}
	.form-item-hr {
		margin: 0 8px;
	}
}
::v-deep .form-item-input-display {
	.el-form-item__content {
		.el-input.is-disabled .el-input__inner {
			background-color: #fff;
			color: #606266;
		}
	}
}
</style>
