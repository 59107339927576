<template>
	<!-- 班组 -->
	<div id="table-component-box">
		<p class="box-title">班组管理</p>
		<el-button
			type="primary"
			style="margin-bottom: 10px"
			:disabled="tabpaneActiveName === '3' ? true : false"
			@click="openGroupEdit('add')"
			>新增</el-button
		>
		<el-tabs v-model="tabpaneActiveName" @tab-click="tabpaneHandleClick">
			<el-tab-pane label="按时间换班" name="1"></el-tab-pane>
			<el-tab-pane label="按趟次换班" name="2"></el-tab-pane>
			<!-- <el-tab-pane label="人车资源管理" name="3"></el-tab-pane> -->
		</el-tabs>
		<!-- ========== -->
		<el-table
			v-loading="groupListLoading"
			element-loading-text="数据加载中..."
			element-loading-spinner="el-icon-loading"
			:data="tableData"
			style="width: 100%"
		>
			<el-table-column prop="groupName" label="组名"> </el-table-column>
			<el-table-column prop="planNum" label="负责班次"> </el-table-column>
			<el-table-column prop="timeShiftTypeName" label="关联班制"> </el-table-column>
			<el-table-column prop="s_end_time" label="班制首末时间"> </el-table-column>
			<el-table-column prop="vehicleNum" label="车辆"> </el-table-column>
			<el-table-column prop="driverNum" label="司机"> </el-table-column>

			<el-table-column label="操作" width="160">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="openGroupEdit('detail', scope.row)">查看</el-button>
					<el-button type="text" size="small" @click="openGroupEdit('edit', scope.row)">编辑</el-button>
					<el-button type="text" size="small" @click="delHandleClick(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>

		<groupEdit
			ref="groupEdit"
			:routeId="routeId"
			:dataList="dataList"
			:shiftChangeType="tabpaneActiveName"
			@refreshList="getData"
		></groupEdit>
	</div>
</template>

<script>
import groupEdit from "./group-edit.vue";
export default {
	components: { groupEdit },
	props: {
		routeId: {
			type: String,
			default: ""
		},
		dataList: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			groupListLoading: false,
			tabpaneActiveName: "1",
			tableData: []
		};
	},
	watch: {
		routeId: {
			handler: function (val) {
				if (val) {
					this.getData();
				}
			},
			immediate: true
		}
	},
	methods: {
		tabpaneHandleClick(tab, event) {
			console.log(this.tabpaneActiveName, event);
			if (this.tabpaneActiveName !== "3") {
				this.getData();
			}
		},

		getData() {
			let url = "/schedule/group/list";
			let param = {
				pageNum: 1,
				pageSize: 20,
				routeId: this.routeId,
				shiftChangeType: this.tabpaneActiveName // 1-按时间换班，2-按趟次换班
			};
			this.groupListLoading = true;
			this.$http
				.post(url, param, { isRequestParam: false })
				.then(({ detail }) => {
					let timeShiftsTypeOptions = {
						1: "全天班",
						2: "早班",
						3: "晚班",
						4: "早晚班"
					};

					this.tableData = detail.map(item => {
						return {
							...item,
							timeShiftTypeName: item.groupName + " - " + timeShiftsTypeOptions[item.shiftDetailVo.timeShiftType],
							s_end_time: item.shiftDetailVo.startTime + " ~ " + item.shiftDetailVo.endTime
						};
					});
					console.log(this.tableData, "this.tableData");
				})
				.finally(() => {
					this.groupListLoading = false;
				});
		},

		openGroupEdit(type, _data) {
			this.$refs["groupEdit"].open(type, _data);
		},

		delHandleClick(_data) {
			let url = "/schedule/group/removeById";
			let param = {
				id: _data.id
			};
			this.$http
				.post(url, param)
				.then(res => {
					if (res.code === "0") {
						this.$message({
							message: `删除成功`,
							type: "success"
						});
						this.getData();
					}
				})
				.catch(() => {});
		}
	}
};
</script>

<style scoped lang="scss">
.box-title {
	border-bottom: 1px solid #e6ebf5;
	color: #333;
	padding: 14px 15px 7px;
	margin-bottom: 15px;
	font-weight: 700;
	font-size: 16px;
}
</style>
